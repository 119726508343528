/* istanbul ignore file */
import { graphql } from "gatsby";
import React from "react";
import { ParagraphDataFragment } from "../../../graphql-types";
import { RichTextRenderer } from "../RichTextRenderer";

type Props = ParagraphDataFragment;

export default function Paragraph({ body }: Props) {
  return (
    <RichTextRenderer
      className="font-light text-gray-500 inline-html max-w-lg mx-auto text-left px-4"
      component="div"
      raw={body.raw}
    />
  );
}

export const fragment = graphql`
  fragment ParagraphData on Paragraph {
    body {
      raw
    }
  }
`;
